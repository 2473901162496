//enum list to maintain mapping between componentName and widgetId.
export enum WidgetId {
  my_work_cards = 16,
  project_management = 23,
  planner = 24,
  inspection_queue = 41
};

// enum for audit review status
export enum AuditReviewStatus {
  tech = 'TECH',
  prodController = 'PROD_CONTROLLER',
  custRep = 'CUST_REP',
  final = 'FINAL'
}

// enum for audit review status
export enum AuditApprovalStatus {
  notStarted = 'NOT_STARTED',
  inProgress = 'IN_PROGRESS',
  accept = 'ACCEPT',
  reject = 'REJECT',
  blank = 'BLANK',
  disabled = 'DISABLED'
}

// Routine inspection action status
export enum RoutineInspectionFlag {
  accept = 'ACCEPT',
  reject = 'REJECT',
  cancel = 'CANCEL-INSP',
  init = 'INIT-INSP'
}

// Routine customer approval flags
export enum RoutineCustomerApprovalFlag {
  accept = 'ACCEPT',
  reject = 'REJECT',
  notStarted = 'NOT_STARTED',
  selected = 'SELECTED'
}

// Work card activityCode enum
export enum RequestTypeEnum {
  inProgress = 'RQST-IN-PROCESS-INSP',
  ndt = 'RQST-NDT-INSP',
  finalBuyback = 'RQST-FINAL-INSP'
}

// Work card status enum
export enum WCStatusEnum {
  closed = 'CLOSED',
  hold = 'HOLD',
  ready = 'READY'
}

// Work card status reason enum
export enum WCStatusReasonEnum {
  audit = 'AUDIT',
  kickBack = 'KICKBACK',
  completed = 'COMPLETED'
}

// Work order type enum
export enum WorkOrderTypeEnum {
  routine = 'routine',
  nonRoutine = 'nonroutine'
}
// Persona enum
export enum PersonaEnum {
  tech = 'technician',
  records = 'records',
  lead = 'lead',
  customer = 'customer',
  kickback = 'kickback',
  inspector = 'inspector',
  planner = 'planner',
  cardClosure = 'cardclosure',
  discrepancy = 'discrepancy'
}

// Note operation enum
export enum WCNoteOperationsEnum {
  insert = 'INSERT',
  update = 'UPDATE'
}

// Stamps enum
export enum StampEnum {
  partial = '/',
  noDot = 'No dot',
  na = 'N/A',
  pcw = 'PCW',
  ng = "NG",
  nr = "N/R",
  pa = "PA",
  transfer = "TRANSFER",
  void = "VOID",
  x = "X"
}

// stamp type
export enum StampTypeEnum {
  cir = 'CIR_STAMP',
  customer = 'CUSTOMER_STAMP',
  tech = 'TECH_STAMP',
  lead = 'LEAD_STAMP',
  inspector = 'INSPECTOR_STAMP',
  planner = 'PLANNER_STAMP',
  text = 'TEXT',
  transfer = 'TRANSFER',
  notgood = 'NOT_GOOD',
  partial ='PARTIAL_WORK',
  visualSignature = 'VISUAL_SIGNATURE',
  visualInitials = 'VISUAL_INITIALS',
  withdrawn = 'WITHDRAWN_STAMP'
}

// stamp scale enaum
export enum StampScaleEnum {
  step = 2,
  cir = 1.2,
  custAccept = .9,
  text = 1.6,
  cust = 1,
}

// Routine stamp status enum
export enum RoutineStampStatusEnum {
  hold = 'HOLD',
  closed = 'CLOSED',
  inWork = 'IN-WORK'
}

// Routine stamp status reason enum
export enum RoutineStampStatusReasonEnum {
  accepted = 'ACCEPTED',
  inspector = 'INSPECTOR',
  completed = 'COMPLETED',
  maint = 'MAINT',
  customer = 'CUSTOMER'
}

// Routine stamp operation enum
export enum RoutineStampOperationEnum {
  insert = 'INSERT',
  update = 'UPDATE',
  delete = 'DELETE'
}

// Routine Annotation Type
export enum AnnotationTypeEnum {
  stamp = 'STAMP',
  annotation = 'ANNOTATION'
}
